import React, { useState, useEffect, useContext, useCallback } from 'react'
import { toast } from 'react-toastify'
import { Form, Button, Card, Columns } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlayCircle,
  faArrowRight,
  faSync,
  faExclamationCircle,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-regular-svg-icons'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import ReactMarkdown from 'react-markdown'
import cx from 'classnames'
import { isHttpsUri } from 'valid-url'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import styled from '@emotion/styled'
import 'react-toastify/dist/ReactToastify.css'
import Styles from '../../styles/lab.module.scss'
import 'react-tabs/style/react-tabs.css'
import '../../styles/lab.scss'

import Runner from './Runner'
import Exercises from './exercises'
import SuiteResults from './SuiteResults'
import CopyBox from './CopyBox'
import LabResults from './LabResults'
import {
  Payload,
  ExampleInput,
  ExampleOutput,
  NoPayload,
  ActualOutput,
} from './Payload'

const { Field, Control, Label, Input } = Form

toast.configure({
  autoClose: 5000,
  draggable: false,
  hideProgressBar: true,
  className: 'wegnology-notification',
  bodyClassName: 'wegnology-notification-body',
  position: toast.POSITION.BOTTOM_CENTER,
})

const LabCard = styled(Card)`
  box-shadow: none;
  border: 1px solid rgb(227, 229, 231);
`

// We need this because Gatsby is SSR and window is not available at compile time
const windowGlobal = typeof window !== 'undefined' && window

const ExercisesDispatch = React.createContext(null)

const LabIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
`

function ReactMarkdownCode(props) {
  const { children } = props
  return <code className="language-text">{children}</code>
}

function ReactMarkdownLink(props) {
  const { href, children } = props
  return (
    <a target="_blank" rel="noopener noreferrer" href={href}>
      {children}
    </a>
  )
}

const ReactMarkdownRenderers = {
  inlineCode: ReactMarkdownCode,
  link: ReactMarkdownLink,
}

const WorkflowLab = () => {
  const [
    exercises,
    setExercises,
    runExercise,
    runSuite,
    runAll,
    counter,
    counterDispatch,
  ] = Runner(Exercises)
  const handleClick = useCallback(() => {
    if (!exercises.meta.isRunning) runAll()
  }, [exercises, runAll])

  return (
    <>
      <LabCard style={{ borderRadius: 4 }}>
        <Card.Content style={{ padding: '1.1em' }}>
          <h2 style={{ marginTop: 0, color: 'black' }}>Running Tests</h2>
          <p>
            Each test will trigger a{' '}
            <a target="_blank" href="/workflows/triggers/webhook/">
              WEGnology Webhook
            </a>{' '}
            with input data. Your workflow is expected to reply to the Webhook
            with the correct output data.
          </p>
          <p>
            Next to the name of each test and test suites, you'll see a small
            run button (
            <FontAwesomeIcon icon={faPlayCircle} color="#1E5463" />
            ). While you're solving each test, it's recommended you use these to
            run just the specific test or test suite you're working on. If you
            think you've got everything solved and want to run the entire lab,
            you can do so with the button below:
          </p>
          <p style={{ textAlign: 'center' }}>
            <Button
              onClick={handleClick}
              id="run-all"
              style={{
                background: '#7CAC19',
                color: '#FFFFFF',
                textTransform: 'uppercase',
                fontSize: 14,
                fontFamily:
                  "'Alternate Gothic', 'Franklin Gothic Medium', 'Arial Narrow', sans-serif",
              }}
            >
              {exercises.meta.isRunning ? 'Running...' : 'Run All Tests'}
            </Button>
          </p>
        </Card.Content>
      </LabCard>
      <LabCard style={{ borderRadius: 4 }}>
        <Card.Content style={{ padding: '1.1em' }}>
          <LabResults counter={counter} />
        </Card.Content>
      </LabCard>

      <ExercisesDispatch.Provider
        value={{
          runExercise,
          runSuite,
          setExercises,
          counter,
          counterDispatch,
        }}
      >
        <ExerciseGroup exercises={exercises} key="Math" groupName="Math" />
        <ExerciseGroup exercises={exercises} key="Mutate" groupName="Mutate" />
        <ExerciseGroup exercises={exercises} key="Array" groupName="Array" />
        <ExerciseGroup exercises={exercises} key="String" groupName="String" />
        <ExerciseGroup
          exercises={exercises}
          key="TemplateHelpers"
          groupName="TemplateHelpers"
        />
        <ExerciseGroup
          exercises={exercises}
          key="WorkflowStorage"
          groupName="WorkflowStorage"
        />
        <ExerciseGroup
          exercises={exercises}
          key="EncodingDecoding"
          groupName="EncodingDecoding"
        />
        <ExerciseGroup
          exercises={exercises}
          key="Validation"
          groupName="Validation"
        />
      </ExercisesDispatch.Provider>
    </>
  )
}

const ExerciseGroup = ({ exercises, groupName }) => {
  const { runSuite, setExercises, counter, counterDispatch } =
    useContext(ExercisesDispatch)

  // This will save the users Webhook in the Browser's Local Storage
  const localStorageWebhookName = `workflowLabWebhookUrl-${groupName}`
  const localStorageWebhook = windowGlobal
    ? window.localStorage.getItem(localStorageWebhookName)
    : null

  const [webhook, setWebhook] = useState(localStorageWebhook || null)

  useEffect(() => {
    exercises[groupName].webhook = webhook
    setExercises({ ...exercises })
    counterDispatch({
      type: 'init',
      suite: groupName,
      count: Object.keys(exercises[groupName].exercises).length,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webhook]) // Only re-run the effect if count changes

  const onWebhookChange = useCallback(
    (event) => {
      if (windowGlobal) {
        window.localStorage.setItem(localStorageWebhookName, event.target.value)
      }
      setWebhook(event.target.value)
    },
    [setWebhook, localStorageWebhookName]
  )

  const exerciseGroup = exercises[groupName]
  const allExercises = Object.values(exerciseGroup.exercises).map(
    (exercise) => {
      return (
        <Exercise
          key={exercise.name + exercise.label}
          exercise={exercise}
          groupName={groupName}
        />
      )
    }
  )

  const handleRunButton = useCallback(
    (e) => {
      e.stopPropagation()
      e.preventDefault()
      if (!exercises[groupName].webhook) {
        if (!toast.isActive('invalidWebhook3')) {
          toast.error(
            `${exercises[groupName].name} Test Suite: Invalid Webhook`,
            {
              toastId: 'invalidWebhook3',
            }
          )
        } else {
          toast.update('invalidWebhook3', {
            autoClose: 5000,
            render: `${exercises[groupName].name} Test Suite: Invalid Webhook`,
          })
        }

        return
      }
      if (!exerciseGroup.isRunning) runSuite(groupName)
    },
    [exerciseGroup, exercises, groupName, runSuite]
  )

  const SuiteTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
  `
  return (
    <Accordion preExpanded={['Math']} allowZeroExpanded>
      <LabCard style={{ borderRadius: 4 }}>
        <SuiteResults counter={counter} suite={groupName} />

        <AccordionItem uuid={groupName}>
          <AccordionItemHeading>
            <AccordionItemButton
              style={{
                outline: 'none',
              }}
              data-id={`accordion-${groupName}`}
            >
              <Card.Content style={{ display: 'flex', padding: '1.1em' }}>
                <SuiteTitleWrapper>
                  <h2 style={{ margin: 0, color: 'black' }}>
                    {exerciseGroup.name}
                  </h2>
                  <PlayButton
                    isRunning={exerciseGroup.isRunning}
                    text="Run Test Suite"
                    id={`run-suite-${groupName}`}
                    action={handleRunButton}
                  />
                </SuiteTitleWrapper>
                <SuiteRatios
                  counter={counter}
                  suite={exerciseGroup}
                  groupName={groupName}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifySelf: 'flex-end',
                  }}
                >
                  <FontAwesomeIcon icon={faAngleDown} color="#7e7374" />
                </div>
              </Card.Content>
            </AccordionItemButton>
          </AccordionItemHeading>

          <AccordionItemPanel>
            <Card.Content
              style={{
                paddingTop: 0,
              }}
            >
              <ReactMarkdown
                escapeHtml={false}
                source={exerciseGroup.description}
                renderers={ReactMarkdownRenderers}
              />
              <Field>
                <Label className={Styles.Webhook}>Webhook URL</Label>
                <Control>
                  <Input
                    className={Styles.webhookInput}
                    type="text"
                    id={`webhook-${groupName}`}
                    value={webhook || ''}
                    onChange={onWebhookChange}
                    placeholder="http://triggers.app.wnology.io/webhooks/your-webhook-id"
                  />
                </Control>
                <p
                  style={{
                    fontSize: 11,
                    marginTop: 0,
                    fontStyle: 'italic',
                    color: '#888C95',
                  }}
                >
                  For each test in this suite, the input data will be POSTed to
                  the URL above. The specific Test ID will be added as a query
                  param (e.g.
                  https://triggers.app.wnology.io/webhooks/your-webhook-id?id=
                  {Object.keys(exerciseGroup.exercises)[0]}).
                </p>
              </Field>
              {allExercises}
            </Card.Content>
          </AccordionItemPanel>
        </AccordionItem>
      </LabCard>
    </Accordion>
  )
}

const Exercise = ({ exercise, groupName }) => {
  const { runExercise } = useContext(ExercisesDispatch)

  const handleRunButton = useCallback(
    (e) => {
      e.stopPropagation()
      e.preventDefault()
      if (!exercise.isRunning) runExercise(groupName, exercise.label)
    },
    [exercise, runExercise, groupName]
  )

  const className = cx(Styles.navLink, {
    [Styles.exercise]: true,
    [Styles.goodExercise]: exercise.passed,
    [Styles.badExercise]: !exercise.passed && exercise.run,
    [Styles.staticExercise]: !exercise.passed && !exercise.run,
  })

  const examples = exercise.examples.map((context, index) => {
    return (
      <Columns multiline={false} key={index} style={{ marginBottom: 0 }}>
        <Columns.Column>
          {index === 0 ? (
            <h4 style={{ margin: 0 }} className={Styles.Header}>
              Example Input
            </h4>
          ) : null}
          <ExampleInput input={context.input} />
        </Columns.Column>
        <Columns.Column>
          {index === 0 ? (
            <h4 style={{ margin: '0 0 0 13px' }} className={Styles.Header}>
              Example Output
            </h4>
          ) : null}
          <div style={{ display: 'flex' }}>
            <FontAwesomeIcon
              icon={faArrowRight}
              color="#A0A3AA"
              style={{
                margin: 'auto 0',
                marginLeft: '-10px',
                marginRight: '10px',
              }}
            />
            <ExampleOutput output={context.output} body={context.body} />
          </div>
        </Columns.Column>
      </Columns>
    )
  })

  const TestTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
  `

  return (
    <Accordion preExpanded={['add-two-numbers']} allowZeroExpanded>
      <LabCard className={className} style={{ borderRadius: 4 }}>
        <AccordionItem uuid={exercise.label}>
          <AccordionItemHeading>
            <AccordionItemButton
              style={{
                outline: 'none',
              }}
            >
              <Card.Content style={{ display: 'flex', padding: '1.1em' }}>
                <TestTitleWrapper>
                  <h3 id={exercise.label} size={5} style={{ margin: 0 }}>
                    {exercise.name}
                  </h3>
                  <PlayButton
                    isRunning={exercise.isRunning}
                    text="Run Test"
                    id={`run-test-${groupName}-${exercise.label}`}
                    action={handleRunButton}
                  />
                </TestTitleWrapper>
                <ExerciseStatus exercise={exercise} />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifySelf: 'flex-end',
                  }}
                >
                  <FontAwesomeIcon icon={faAngleDown} color="#7e7374" />
                </div>
              </Card.Content>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Card.Content
              style={{
                paddingTop: 0,
              }}
            >
              <CopyBox label="Test ID" showText copyText={exercise.label} />
              <ReactMarkdown
                escapeHtml={false}
                source={exercise.action}
                renderers={ReactMarkdownRenderers}
              />
              <ReactMarkdown
                escapeHtml={false}
                source={exercise.recommended}
                renderers={ReactMarkdownRenderers}
              />
              {examples}
              <ExerciseResults exercise={exercise} multi={exercise.multi} />
            </Card.Content>
          </AccordionItemPanel>
        </AccordionItem>
      </LabCard>
    </Accordion>
  )
}

const ExerciseResults = ({ exercise, multi }) => {
  if (exercise.isRunning) {
    return (
      <>
        <h3 style={{ marginTop: '15px' }}>Results</h3>
        <hr style={{ margin: 0 }} />
        <Columns>
          <Columns.Column>
            <NoPayload centered text="This test is running." />
          </Columns.Column>
        </Columns>
      </>
    )
  }
  if (!exercise.run || exercise.context.length === 0) {
    return (
      <>
        <h3 style={{ marginTop: '15px' }}>Results</h3>
        <hr style={{ margin: 0 }} />
        <Columns>
          <Columns.Column>
            <NoPayload centered text="This test has not been run yet." />
          </Columns.Column>
        </Columns>
      </>
    )
  }

  const tabList = []
  const tabPanelList = []

  const trailFontColor = function (success, error) {
    if (error) {
      return 'rgb(254, 18, 9)'
    }

    return 'rgb(124, 172, 25)'
  }

  const TrialTab = styled(Tab)`
    display: inline-block;
    border: none;
    border-radius: none;
    margin-bottom: 1px !important;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
  `

  const TrialPanel = styled(TabPanel)`
    padding: 0px 10px;
  `

  TrialTab.tabsRole = 'Tab'
  TrialPanel.tabsRole = 'TabPanel'

  if (!multi) {
    exercise.context.forEach((context, index) => {
      const { passed } = context
      const failed = !context.passed && exercise.run
      const trialColor = trailFontColor(passed, failed)
      const tabClass = failed ? 'fail' : 'pass'
      tabList.push(
        <TrialTab
          style={{
            color: trialColor,
          }}
          className={tabClass}
          key={`${exercise.label}-actual-tab-${index}`}
        >
          Trial {index + 1}
        </TrialTab>
      )

      tabPanelList.push(
        <TrialPanel key={`${exercise.label}-actual-tabpanel-${index}`}>
          <Columns multiline={false} style={{ marginBottom: 0 }}>
            <Columns.Column>
              <h4 style={{ margin: 0 }} className={Styles.Header}>
                Actual Input
              </h4>
              <Payload data={context.input} />
            </Columns.Column>

            <Columns.Column>
              <h4
                style={{ margin: 0, paddingLeft: '13px' }}
                className={Styles.Header}
              >
                Expected Output
              </h4>
              <div style={{ display: 'flex' }}>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  color="#A0A3AA"
                  style={{
                    margin: 'auto 0',
                    marginLeft: '-10px',
                    marginRight: '10px',
                  }}
                />
                <Payload data={context.expected} />
              </div>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <h4 style={{ margin: 0 }} className={Styles.Header}>
                Actual Output
              </h4>
              <ActualOutput
                output={context.actual}
                success={context.passed}
                message={context.message}
                error={!context.passed && exercise.run}
                body={context.body}
              />
            </Columns.Column>
          </Columns>
        </TrialPanel>
      )
    })
  } else {
    exercise.context.forEach((context, index) => {
      const contextLength = context.length - 1
      const payloads = []
      const actual = []

      context.forEach((currentContext, contextIndex) => {
        payloads.push(
          <Columns
            key={`${currentContext.passed}-payloads-${contextIndex}`}
            multiline={false}
            style={{ marginBottom: 0 }}
          >
            <Columns.Column>
              <Payload data={currentContext.input} />
            </Columns.Column>
            <Columns.Column>
              <div style={{ display: 'flex' }}>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  color="#A0A3AA"
                  style={{
                    margin: 'auto 0',
                    marginLeft: '-10px',
                    marginRight: '10px',
                  }}
                />
                <Payload data={currentContext.expected} />
              </div>
            </Columns.Column>
          </Columns>
        )
        actual.push(
          <ActualOutput
            key={`${currentContext.passed}-actualOutput-${contextIndex}`}
            output={currentContext.actual}
            success={currentContext.passed}
            message={currentContext.message}
            error={!currentContext.passed && exercise.run}
            body={currentContext.body}
          />
        )
      })

      tabList.push(
        <TrialTab
          style={{
            color: trailFontColor(
              context[contextLength].passed,
              !context[contextLength].passed && exercise.run
            ),
          }}
          key={`${exercise.label}-actual-tab-${index}`}
        >
          Trial {index + 1}
        </TrialTab>
      )

      tabPanelList.push(
        <TrialPanel key={`${exercise.label}-actual-tabpanel-${index}`}>
          <Columns multiline={false} style={{ marginBottom: 0 }}>
            <Columns.Column>
              <h4 style={{ margin: 0 }} className={Styles.Header}>
                Actual Input
              </h4>
            </Columns.Column>

            <Columns.Column>
              <h4
                style={{ margin: 0, paddingLeft: '13px' }}
                className={Styles.Header}
              >
                Expected Output
              </h4>
            </Columns.Column>
          </Columns>
          {payloads}
          <Columns>
            <Columns.Column>
              <h4 style={{ margin: 0 }} className={Styles.Header}>
                Actual Output
              </h4>
              {actual}
            </Columns.Column>
          </Columns>
        </TrialPanel>
      )
    })
  }

  return (
    <>
      <h3 style={{ marginTop: '15px' }}>Results</h3>
      <hr style={{ marginTop: 0 }} />
      <Tabs>
        <TabList>{tabList}</TabList>

        {tabPanelList}
      </Tabs>
    </>
  )
}

const PlayButton = ({ isRunning, action, text, id }) => {
  const SpinLabIcon = styled(LabIcon)`
    display: inline-block;
    font-size: 14px;
    margin-left: 8px;
    background: #58bfc6;
    padding: 5px;
    border-radius: 50%;
  `

  const DaButton = styled.button`
    padding: 0px;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  `

  const PlayIcon = styled(LabIcon)`
    margin-left: 8px;
  `

  const PlayText = styled.span`
    color: #7e7374;
    margin-left: 8px;
    vertical-align: middle;
    text-transform: uppercase;
    cursor: pointer;
    font-family: 'Alternate Gothic', 'Franklin Gothic Medium', 'Arial Narrow',
      sans-serif;
  `
  const spinningIcon = (
    <SpinLabIcon
      className={Styles.ldsDualRing}
      icon={faSync}
      spin
      color="#ffffff"
    />
  )

  const playIcon = <PlayIcon icon={faPlayCircle} color="#1E5463" />

  const icon = isRunning ? spinningIcon : playIcon

  return (
    <>
      <DaButton id={id} type="button" onClick={action}>
        {icon}
      </DaButton>
      <PlayText onClick={action}>{isRunning ? 'Running...' : text}</PlayText>
    </>
  )
}

const StatusTheWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`

const StatusLabel = styled.span`
  color: ${(props) => props.color};
  font-size: 16px;
  padding-left: 8px;
  vertical-align: middle;
  text-transform: uppercase;
  font-family: 'Alternate Gothic', 'Franklin Gothic Medium', 'Arial Narrow',
    sans-serif;
`

const ExerciseStatus = ({ exercise }) => {
  if (exercise.isRunning) {
    return <></>
  }

  if (!exercise.passed && exercise.run) {
    return (
      <StatusTheWrapper
        data-exercise={exercise.label}
        data-pass={true} // eslint-disable-line react/jsx-boolean-value
        data-fail={false}
      >
        <LabIcon icon={faTimesCircle} color="#D92A2A" />
        <StatusLabel color="#d92a2a">Fail!</StatusLabel>
      </StatusTheWrapper>
    )
  }

  if (exercise.passed) {
    return (
      <StatusTheWrapper
        data-exercise={exercise.label}
        data-pass={false}
        data-fail={true} // eslint-disable-line react/jsx-boolean-value
      >
        <LabIcon icon={faCheckCircle} color="#8DB319" />
        <StatusLabel color="#8DB319">Pass!</StatusLabel>
      </StatusTheWrapper>
    )
  }

  return <></>
}

const SuiteRatios = ({ suite, counter, groupName }) => {
  const suiteCounter = counter[groupName]

  // nothing to display if running or the counter hasn't been init yet.
  if (!suiteCounter || suite.isRunning) {
    return <></>
  }

  // if at least one fails, show the failure
  if (counter[groupName].fail >= 1) {
    return (
      <StatusTheWrapper>
        <LabIcon icon={faTimesCircle} color="#D92A2A" />
        <StatusLabel
          data-suite={groupName}
          data-passcount={counter[groupName].pass}
          data-failcount={counter[groupName].fail}
          data-pass={false}
          data-fail={true} // eslint-disable-line react/jsx-boolean-value
          color="#d92a2a"
        >
          ({counter[groupName].pass}/{counter[groupName].count}) Fail!
        </StatusLabel>
      </StatusTheWrapper>
    )
  }

  if (counter[groupName].fail === 0 && counter[groupName].pass >= 1) {
    return (
      <StatusTheWrapper>
        <LabIcon icon={faCheckCircle} color="#8DB319" />
        <StatusLabel
          data-suite={groupName}
          data-pass={true} // eslint-disable-line react/jsx-boolean-value
          data-fail={false}
          data-passcount={counter[groupName].pass}
          data-failcount={counter[groupName].fail}
          color="#8DB319"
        >
          ({counter[groupName].pass}/{counter[groupName].count}) Pass!
        </StatusLabel>
      </StatusTheWrapper>
    )
  }

  if (!suite.webhook || !isHttpsUri(suite.webhook)) {
    return (
      <StatusTheWrapper>
        <LabIcon icon={faExclamationCircle} color="#D92A2A" />
        <StatusLabel
          data-suite={groupName}
          data-pass={false}
          data-fail={false}
          data-passcount={0}
          data-failcount={0}
          color="#D92A2A"
        >
          No Webhook Provided
        </StatusLabel>
      </StatusTheWrapper>
    )
  }

  return <></>
}

export default WorkflowLab

export { ExerciseGroup, ExercisesDispatch }
